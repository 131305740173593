
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import useNavigation from '@/composables/useNavigation';
import useDeskBooking from '@/composables/useDeskBooking';

export default {
    name: 'DeskBookedConfirmation',
    setup() {
        const { gotoQrScanner, goHome } = useNavigation();
        const route = useRoute();
        const { deskSpaces } = useDeskBooking();

        const deskName = computed(() => {
            const spaceId = route.query.spaceId;
            const spaceData = deskSpaces.value.find((s: Desk) => s.id === spaceId);
            return spaceData?.turf_meta?.space_name;
        });

        return {
            gotoQrScanner,
            goHome,
            deskName
        };
    },
};
